import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Price({ size, color, title, ariaHidden }: ComponentProps) {
  return (
    <>
      <BaseSvgStyle
        size={size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={ariaHidden}
      >
        {title && <title>{title}</title>}

        <BaseIconPathStyle
          stroke={color}
          strokeRound
          d="M3.00977 4C3.00977 3.72953 3.10237 3.5028 3.3063 3.29058C3.51046 3.08771 3.73196 3 4.00977 3H11.1598C11.3011 3 11.4252 3.02586 11.5557 3.08082C11.6841 3.13487 11.7903 3.2077 11.886 3.30338C11.8861 3.30344 11.8862 3.3035 11.8862 3.30355L20.6858 12.1232L20.6862 12.1236C20.8793 12.3166 20.9814 12.5503 20.9898 12.8558C20.9885 13.1502 20.8945 13.3782 20.7062 13.5664L14.0467 20.2259L14.0367 20.2059L13.5462 20.6964C13.3555 20.8871 13.1352 20.98 12.8298 20.98C12.5245 20.98 12.3042 20.8872 12.1135 20.6966C12.1135 20.6966 12.1134 20.6965 12.1133 20.6964L3.32352 11.8966L3.32332 11.8964C3.22428 11.7974 3.14299 11.6804 3.09058 11.556C3.03693 11.4286 3.00977 11.2946 3.00977 11.15V4ZM12.4758 20.3532L12.8294 20.7075L13.1833 20.3536L20.3333 13.2036L20.6865 12.8504L20.3337 12.4968L11.5037 3.64685L11.3572 3.5H11.1498H3.99977H3.49977V4V11.15V11.3568L3.64581 11.5032L12.4758 20.3532ZM7.03977 7.03287C6.882 7.1829 6.71624 7.25 6.49977 7.25C6.29975 7.25 6.12648 7.178 5.97689 7.02999C5.82686 6.87223 5.75977 6.70647 5.75977 6.49C5.75977 6.29 5.83175 6.11674 5.97973 5.96716C6.13615 5.81855 6.31114 5.75 6.51977 5.75C6.71977 5.75 6.89303 5.82199 7.04261 5.96997C7.19122 6.12639 7.25977 6.30138 7.25977 6.51C7.25977 6.71001 7.18777 6.88328 7.03977 7.03287Z"
        />
      </BaseSvgStyle>
    </>
  );
}

export default Price;
